<template>
  <div class='linker'>

    <input type='hidden' :name="name" :value="selected.id">

    <div v-if='selected.id != ""'>
      <div class='field--link'>
        <a :href='selected.url' target="_blank">{{selected.name}}</a>
        <a href='#' class='field--link__remove' @click.prevent.stop="remove"><i class='fas fa-times'></i></a>
      </div>
    </div>

    <div v-if='selected.id == ""'>
      <div class='search search--hitcher' v-click-outside="onClickOutside">
        <icon></icon>
        <input type='text'
          @click="showSearch = true"
          @input="onInput"
          placeholder="Search to select..."
          @keydown.esc="showSearch = false"
          v-model='q' autocomplete="chrome-off">
        <div class='search__box' v-show='showSearch'>
          <ul v-if='results.length > 0'>
            <li class='search__box__li__hitcher' v-for='(result,i) in results' :key="i">
              <h2>
                <a target="_blank" :href='result.url' class='button button--primary button--small'>View</a>
                <a href='#' @click.prevent.stop="add(result.id, result.url, result.name)" class='button button--success button--small'>Choose</a>
                <a href='#' @click.prevent.stop="add(result.id, result.url, result.name)">{{result.name}}<small class='search__small'>{{result.company_name}}</small></a>
              </h2>
            </li>
          </ul>
          <div class='empty' v-if='results.length == 0'>
            <p>No results found.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../components/icon.vue";
import vClickOutside from 'v-click-outside'

export default {
  props: ["company_id", "added", "removed", "existing", "name"],
  mounted () {
    var that = this;
  },
  components: {
    Icon
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside(evt) {
      this.showSearch = false;
      this.arrowCounter = -1;
    },
    remove () {
      this.selected =  {
        id: "",
        url: "",
        name: ""
      }
      this.$emit('removed', true);
    },
    add (id, url, name) {
      this.showSearch = false;
      this.selected =  {
        id: id,
        url: url,
        name: name
      }
      this.$emit('added', this.selected.id);
    },
    onInput () {

      var that = this;

      if (that.q.length > 0) {
        that.showSearch = true;
      } else {
        that.showSearch = false;
      }
      that.$api.get('/searches', {
        params: {
          q: that.q,
          type: "Asset",
          company: that.company_id
        }
      })
      .then(function (response) {
        console.log(response.data)
        that.results = response.data;
      })
    }
  },
  data: function () {
    return {
      q: "",
      results: [],
      showSearch: false,
      selected: {
        name: this.existing.name || "",
        id: this.existing.id || ""
      }
    }
  }
}
</script>
