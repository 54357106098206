<template>
  <div>
    <a class='toggle-pane-button' href='#' v-if='sidebarLevel == 0' @click.prevent.stop='resizeSidebar(1)' v-tooltip.right="'Collapse'"><i class="fas fa-angle-double-left"></i></a>
    <a class='toggle-pane-button' href='#' v-if='sidebarLevel == 1' @click.prevent.stop='resizeSidebar(2)' v-tooltip.right="'Expand'"><i class="fas fa-angle-double-right"></i></a>
    <a class='toggle-pane-button' href='#' v-if='sidebarLevel == 2' @click.prevent.stop='resizeSidebar(0)' v-tooltip.right="'Collapse'"><i class="fas fa-angle-double-left"></i></a>
    <div class='company-sidebar' id='company-sidebar'>
      <nav>
        <template v-for="i in items">
          <a href='#' class='company-sidebar__title' @click.prevent.stop='toggleFolder(i.id)' v-if="filteredData(i).length > 0">
            <h2><i v-if='!isFolderHidden(i.id)' class='fas fa-chevron-down'></i><i v-if='isFolderHidden(i.id)' class='fas fa-chevron-right'></i>{{i.name}}</h2>
          </a>
          <a v-tooltip.right="sidebarLevel == 1 ? link.name : '' " class='company-sidebar__a' v-if='!isFolderHidden(i.id)' :href="link.url" v-for='(link, index) in filteredData(i)' :class='link.selected'>
            <div class="asset-icon" v-show='i.name == "Core"'>
              <i :class="link.icon"></i>
            </div>
            <div class="asset-icon" :style="{background: link.color}" v-show='i.name != "Core"'>
              <i :class="link.icon" :style="{color: link.icon_color}"></i>
            </div>
            <span class="company-sidebar__name">{{link.name}}</span>
            <a :href="link.add_url" v-if='can_add && link.add_url' class="company-sidebar__quick" v-tooltip="'Add...'"><i class='fas fa-plus'></i></a>
            <span class="count" v-if='link.count !== false'>{{link.count}}</span>
          </a>
        </template>
        <div class='company-sidebar__buttons' v-if='!no_hide_blank'>
          <template v-if="!no_hide_blank">
            <a href='#' v-if='!hideEmpty' v-tooltip.right="sidebarLevel == 1 ? 'Hide Empty Items' : ''" @click.prevent.stop='toggleEmpty'><i class='fas fa-eye-slash'></i><span>Hide Empty Items</span></a>
            <a href='#' v-if='hideEmpty' v-tooltip.right="sidebarLevel == 1 ? 'Show Empty Items' : ''" @click.prevent.stop='toggleEmpty'><i class='fas fa-eye'></i><span>Show Empty Items</span></a>
          </template>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: ["items", "can_add", "no_hide_blank"],
  data () {
    return {
      sidebarLevel: 0,
      hideEmpty: false,
      hides: []
    }
  },
  mounted () {
    if (localStorage.getItem('huduSidebarFolderHides')) {
      try {
        this.hides = JSON.parse(localStorage.getItem('huduSidebarFolderHides'));
      } catch(e) {

      }
    }

    if (!this.no_hide_blank) {
      if (localStorage.getItem('huduHideEmptySidebarAssets')) {
        try {
          this.hideEmpty = true;
        } catch(e) {

        }
      }
    }

    if (localStorage.getItem('huduSidebarResizeLevel')) {
      try {
        this.sidebarLevel = localStorage.getItem('huduSidebarResizeLevel')
        this.calculateSidebarWidth(this.sidebarLevel)
      } catch(e) {

      }
    }
  },
  methods: {
    isFolderHidden(id) {
      if (this.sidebarLevel == 1) {
        return false
      }
      return this.hides.includes(id) ? true : false;
    },
    toggleFolder(id) {
      if(this.isFolderHidden(id)) {
        const index = this.hides.indexOf(id);
        if (index > -1) {
          this.hides.splice(index, 1);
          localStorage.setItem('huduSidebarFolderHides', JSON.stringify(this.hides));
        }
      } else {
        this.hides.push(id);
        localStorage.setItem('huduSidebarFolderHides', JSON.stringify(this.hides));
      }
    },
    resizeSidebar (level) {
      this.sidebarLevel = level
      localStorage.setItem('huduSidebarResizeLevel', level);

      this.calculateSidebarWidth(level)
    },
    filteredData(item) {
      return this.hideEmpty ? item.links.filter(i => !(i.name != "Home" && i.count == 0)) : item.links
    },
    calculateSidebarWidth(level) {
      var element = document.getElementById('company-sidebar');
      let root = document.documentElement;

      if (level == 1) {
        element.classList.add("company-sidebar--skinny");
        root.style.setProperty('--sidebarwidth', '85px');
      } else if (level == 2) {
        element.classList.remove("company-sidebar--skinny");
        root.style.setProperty('--sidebarwidth', '380px');
      } else {
        element.classList.remove("company-sidebar--skinny");
        root.style.setProperty('--sidebarwidth', '235px');
      }
    },
    toggleEmpty () {
      this.hideEmpty = !this.hideEmpty;

      if (this.hideEmpty === true) {
        localStorage.hide_assets = true
        localStorage.setItem('huduHideEmptySidebarAssets', true);
      } else {
        localStorage.removeItem('huduHideEmptySidebarAssets')
      }
    }
  }
}
</script>
