
<template>
  <div>
    <div class='form__header'>
      <h3>List Items</h3>
      <a href='#'  v-if='isBulkAdding != true' @click.prevent.stop='isBulkAdding = true' class='button button--secondary'>Bulk Add</a>
      <a href='#' v-if='isBulkAdding == true' @click.prevent.stop='isBulkAdding = false' class='button button--secondary'>Single Add</a>
    </div>
    <template v-if='isBulkAdding'>
      <div class='form-group'>
        <label for='bulk'>Enter Items (One Per Line)</label>
        <textarea v-model='bulkItems' id='bulk'></textarea>
      </div>
      <div class='form-group' style='display: flex;justify-content: flex-end;'>
        <div class='button-group'>
          <a href='#' @click.prevent.stop='isBulkAdding = false' class='button button--secondary'>Cancel</a>
          <a href='#' @click.prevent.stop='bulkConvert' class='button button--primary'>Bulk Add Items...</a>
        </div>
      </div>
    </template>
    <template v-if='!isBulkAdding'>
      <div class='form-group' v-for='(item, index) in list_items' v-show='item._destroy == false'>
        <label class='required' :for='`list_item_input_index${index}`'>Item Name</label>
        <main class='form-group-list-item'>
          <input type='text' v-model='item.name' :ref='`list_item_input_index${index}`'>
          <a href='#' @click.prevent.stop='destroyItem(index)' class='button button--plain button--icon'><i class='fas fa-trash'></i></a>
          <input type='hidden' :name='`list[list_items_attributes][${index}][id]`' v-model='item.id'>
          <input type='hidden' :name='`list[list_items_attributes][${index}][name]`' v-model='item.name'>
          <input type='hidden' :name='`list[list_items_attributes][${index}][_destroy]`' v-model='item._destroy'>
        </main>
      </div>
      <div class='form-group' style='display: flex;justify-content: flex-end;'>
        <div class='button-group'>
          <a href='#' @click.prevent.stop='addItem' class='newkb__sidebar__buttons--button button button--plain'><i class='fas fa-plus'></i>New Item</a>
        </div>
      </div>
      <div class='form-group' style='display: flex;justify-content: flex-end;'>
        <div class='button-group'>
          <input type='submit' value='Save' class='button button--primary'>
        </div>
      </div>
    </template>
  </div>
</template>

<script>


export default {
  props: ["preitems"],
  data () {
    return {
      isShown: false,
      isBulkAdding: false,
      bulkItems: "",
      list_items: [{
        name: "",
        "_destroy": false,
        list_items_attributes: []
      }]
    }
  },
  mounted () {
    this.list_items = this.preitems;
  },
  methods: {
    bulkConvert () {
      var bulk_items = this.bulkItems.split(/\r?\n/);
      for(var i = 0; i < bulk_items.length; i++) {
        this.list_items.push({
          name: bulk_items[i],
          "_destroy": false,
          list_items_attributes: []
        })
      }
      this.isBulkAdding = false;
    },
    destroyItem (index) {
      this.list_items[index]._destroy = true;
    },
    addItem () {
      var that = this;
      this.list_items.push({
        name: "",
        "_destroy": false,
        list_item_attributes: []
      })
      // console.log(that.$refs)
      // // that.$nextTick(() => that.$refs.list_item_input_index0.focus());

    },
    destroySubItem (item, index) {
      item.list_items_attributes[index]._destroy = true;
    }
  },

}
</script>

