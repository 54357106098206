<template>
  <div>
    <flash-message></flash-message>
    <div class='password-circle__picker'>
      <input type='hidden' :value='selected' name='asset_password[color]'>
      <a href='#'  @click.prevent.stop='select("")'>
        <span v-tooltip="'None'" :class='{"password-circle big": true, "selected": selected == ""}'></span>
      </a>
      <a href='#'  @click.prevent.stop='select("red")'>
        <span v-tooltip="'Red'" :class='{"password-circle password-circle--red big": true, "selected": selected == "red"}'></span>
      </a>
      <a href='#'  @click.prevent.stop='select("blue")'>
        <span v-tooltip="'Blue'" :class='{"password-circle big password-circle--blue": true, "selected": selected == "blue"}'></span>
      </a>
      <a href='#'  @click.prevent.stop='select("green")'>
        <span v-tooltip="'Green'" :class='{"password-circle big password-circle--green": true, "selected": selected == "green"}'></span>
      </a>
      <a href='#'  @click.prevent.stop='select("purple")'>
        <span v-tooltip="'Purple'" :class='{"password-circle big password-circle--purple": true, "selected": selected == "purple"}'></span>
      </a>
      <a href='#'  @click.prevent.stop='select("pink")'>
        <span v-tooltip="'Pink'" :class='{"password-circle big password-circle--pink": true, "selected": selected == "pink"}'></span>
      </a>
      <a href='#'  @click.prevent.stop='select("yellow")'>
        <span v-tooltip="'Yellow'" :class='{"password-circle big password-circle--yellow": true, "selected": selected == "yellow"}'></span>
      </a>
    </div>
  </div>

</template>

<script>
import flash from "../lib/flashes.js";
export default {
  props: ["existing", "update_url", "can"],
  data: function () {
    return {
      selected: this.existing || ""
    }
  },

  methods: {
    select (color) {
      if (!this.can) {
        return;
      }
      this.selected = color;

      var that = this;
      that.$api.put(that.update_url, { "asset_password": {"color": that.selected}}).then(function (response) {
        flash('Color Updated!','success')
      }).catch(error => {
        console.log(error)
      })

    }
  }
}
</script>

