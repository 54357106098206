<template>
  <div class='list-picker'>
    <flash-message></flash-message>
    <template v-if='!isAdd'>
      <input type='hidden' :name="name" :value='JSON.stringify({"list_ids": value})''>
        <multiselect :options="all_options"
                    label="name" track-by="id" :multiple='multiple_options' :closeOnSelect='!multiple_options' v-model='selected'></multiselect>
        <div class='button-group'>
          <a href='#' @click.prevent.stop='changeIsAdd' class='button button--success'><i class='fas fa-plus'></i>Add Item</a>
        </div>
    </template>
    <template v-if='isAdd'>
      <div class='input-group'>
        <input v-on:keyup.enter="addItem($event)" type='text' v-model='newListItem' placeholder='Enter a new item...' />
        <a href='#' class='button button--success' @click.prevent.stop='addItem'>
          <i class='fas fa-plus'></i> Add To List
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import flash from "../lib/flashes.js";
export default {
  props: ["options", "required", "name", "prevalue", "multiple_options", "new_list_item_url"],
  components: {
    Multiselect
  },
  mounted () {
    var that = this;
  },
  methods: {
    changeIsAdd () {
      this.isAdd = true;
      function checkEnter(e){
        e = e || event;
        var txtArea = /textarea/i.test((e.target || e.srcElement).tagName);
        return txtArea || (e.keyCode || e.which || e.charCode || 0) !== 13;
        }
      document.querySelector('form').onkeypress = checkEnter;
    },
    addItem (e) {
      var that = this;
      that.$api.post(`${that.new_list_item_url}`, {list_item: that.newListItem}).then(function (response) {
        that.all_options.push({id: response.data.list_item.id, name: response.data.list_item.name });
        that.selected.push({id: response.data.list_item.id, name: response.data.list_item.name });
        that.newListItem = "";
        that.isAdd = false;
      }).catch(error => {
        flash(error.response.data[0],'error')
      })
    }
  },
  watch: {
    selected: function (values) {
      var that = this;

      if (this.multiple_options) {
        var returnArray = [];
        values.forEach((element) => {
          returnArray.push(element.id)
        });
        that.value = returnArray
      } else {
        var returnArray = [];
        returnArray.push(values.id)
        that.value = returnArray
      }
    },
  },
  data: function () {
    return {
      all_options: this.options,
      isAdd: false,
      newListItem: "",
      value: this.prevalue ? this.prevalue : [],
      selected: this.prevalue ? this.prevalue : [],
    }
  }
}
</script>

