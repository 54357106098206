<template>
  <div>
    <div class='password-tags password-tags--show' v-if='!isShown'>
      <a v-for='t in tags' :href="index_url + '?tag=' + t.text + '&folder=' + folder">
        <p>{{t.text}}</p>
      </a>
    </div>
    <div class='button-group' v-if='can'>
      <a href='#' @click.prevent.stop='isShown = true' v-if='!isShown' class='button button--small button--secondary'><i class='fas fa-tags'></i>Tag</a>
    </div>
    <div v-if='isShown && can' class='password-tags__form'>
      <vue-tags-input
        v-model="tag"
        :autocomplete-items="filteredItems"
        :validation="validation"
        :tags="tags"
        @tags-changed="newTags => tags = newTags"
      />
      <a href='#' @click.prevent.stop='save' class='button button--primary'>Save</a>
      <p class='password-tags__hint'>Hit return after typing to enter a tag</p>
    </div>
  </div>
    
</template>

<script>
import flash from "../lib/flashes.js";
import VueTagsInput from '@johmun/vue-tags-input';

export default {
  props: ["index_url", "update_url", "autocomplete_suggestions", "can", "folder"],
  components: {
    VueTagsInput,
  },
   computed: {
    filteredItems() {
      return this.autocompleteItems.filter(i => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
  },
  data: function () {
    return {
      tag: '',
      isShown: false,
      tags: [],
      autocompleteItems: this.autocomplete_suggestions,
      validation: [
      {
        classes: 'min-length',
        rule: tag => tag.text.length < 1,
      }, 
      {
        classes: 'avoid-item',
        rule: /^(?!Cannot).*$/,
        disableAdd: true,
      }, 
      {
        classes: 'no-braces',
        rule: ({ text }) => text.indexOf('{') !== -1 || text.indexOf('}') !== -1,
      }],
    }
  },
  mounted () {
   this.getTags()
  },
  methods: {
    getTags () {
      var that = this;
      that.$api.get(that.update_url + ".json").then(function (response) {
        that.tags = response.data;
      }).catch(error => {
        console.log(error)
      })
    },
    save () {
      var that = this;
      that.$api.put(that.update_url, {"tags": that.tags}).then(function (response) {
        flash('Tags Updated!','success')
        that.isShown = false
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

